/**
 * @file Tailwind configuration.
 */
import plugin from 'tailwindcss/plugin'
import * as SLIDESHOW_TEXT_CLASSNAMES from './app/javascript/bits/slideshow_classnames'
import containerQueriesPlugin from './app/javascript/bits/tailwindcss_container_queries_plugin'

// Tailwindcss color can be both `{ 'pink-500': '#FF4081' }` and `{ pink: { 500: '#FF4081'} }`,this function helps flatten everything
const flattenColorTheme = (colorOptions) =>
  Object.assign(
    {},
    ...Object.entries(colorOptions).flatMap(([color, values]) =>
      typeof values === 'object'
        ? Object.entries(flattenColorTheme(values)).map(([number, hex]) => ({
            [color + (number === 'DEFAULT' ? '' : `-${number}`)]: hex,
          }))
        : [{ [`${color}`]: values }],
    ),
  )

/**
 * Helper function to add selectors to all of the rules a variant is being applied to
 * @param {PostCSS.Container} container - PostCSS container containing all of the rules the variant is being applied to
 * @param {*} modifierFunction - function that return the list of new selectors
 * @returns {PostCSS.AtRule[]} new rules
 * @see https://postcss.org/api/#container
 */
function addSelectors(container, modifierFunction /*: (string) => string */) {
  const rules /*: AtRule[] */ = []
  // @see https://postcss.org/api/#atrule-walkrules
  container.walkRules((rule) => {
    // Slice 1 to remove `.` at the beginning for tailwind classes
    const selectors = rule.selectors.map((selector) => modifierFunction(selector.slice(1)))
    rules.push(rule.clone({ selectors }))
  })

  return rules
}

export default {
  corePlugins: {
    preflight: false,
    container: false,
  },
  content: [
    './app/javascript/**/*.vue',

    // Need attachments.ts for attachment loading animation tailwindcss class mapping
    './app/javascript/bits/attachments.ts',

    // Need post_color.ts for post color value - tailwindcss class mapping
    './app/javascript/bits/post_color.ts',

    // Need font.ts for wall font settings - tailwind font class mapping
    './app/javascript/bits/font.ts',

    // Include slideshow class names
    './app/javascript/vuecomposables/slideshow_styles.ts',

    // Need chiclet_color.ts for chiclet color value - tailwindcss class mapping
    './app/javascript/bits/chiclet_color.ts',

    // Need whiteboard.ts for whiteboard color value - tailwindcss class mapping
    './app/javascript/bits/whiteboard.ts',

    // Include button skin based Tailwind classes for buttons on the surface
    './app/javascript/vuecomposables/surface_button_skin_based_tw_classes.ts',

    // Include UPE classes
    '../../packages/universal-post-editor/src/**/*.{vue,js,ts,jsx,tsx}',
  ],
  theme: {
    /**
     * Orizuru Colors at the bottom with some outliers at the top
     * @see https://www.figma.com/file/VlbM3Jyn8sDJYveYBJEGvl/Orizuru?node-id=639%3A86
     *
     * Note: we override colors instead of extending them as we don't use any built-in tw colors.
     */
    colors: {
      // Defaults copied from tailwindcss/colors
      inherit: 'inherit',
      current: 'currentColor',
      transparent: 'transparent',
      black: '#000', // we treat white differently - see below.

      // Modal
      'modal-overlay-light': 'rgba(0, 0, 0, 0.2)',
      'modal-overlay-dark': 'rgba(36, 37, 39, 0.8)',
      'modal-overlay-grey': 'rgba(204, 204, 204, 0.1)',
      'modal-overlay-grape': 'rgba(148, 102, 232, 0.5)',
      'modal-overlay-grape-alpha-10': 'rgba(148, 102, 232, 0.1)',
      'modal-overlay-grape-alpha-20': 'rgba(148, 102, 232, 0.2)',
      'modal-overlay-canary': 'rgba(254, 192, 6, 0.35)',
      'modal-overlay-canary-alpha-10': 'rgba(254, 192, 6, 0.1)',
      'modal-overlay-canary-alpha-20': 'rgba(254, 192, 6, 0.2)',
      'modal-top-light': '#F6F6F6',
      'modal-top-dark': '#202123',
      popover: {
        light: 'rgba(255, 255, 255, 0.86)',
        dark: 'rgba(0, 0, 0, 0.86)',
      },
      'popover-overlay': {
        default: 'rgba(0, 0, 0, 0.15)',
        light: 'rgba(0, 0, 0, 0.15)',
        dark: 'rgba(255, 255, 255, 0.05)',
      },
      'transparent-button': {
        light: 'rgba(0,0,0,0.05)',
        dark: 'rgba(255,255,255,0.1)',
        'hover-light': 'rgba(0,0,0,0.1)',
        'hover-dark': 'rgba(255,255,255,0.15)',
      },
      button: {
        light: 'rgba(0, 18, 52, 0.06)',
        dark: 'rgba(239, 244, 250, 0.1)',
        'hover-light': 'rgba(1, 10, 34, 0.18)',
        'hover-dark': 'rgba(239, 244, 250, 0.2)',
        'disabled-light': 'rgba(0, 18, 52, 0.04)',
        'disabled-dark': 'rgba(239, 244, 250, 0.14)',
      },
      'attachment-overlay': {
        light: 'rgba(0,0,0,0.08)',
        dark: 'rgba(255,255,255,0.08)',
      },
      'attachment-preview-border': {
        light: 'rgba(0,0,0,0.1)',
        dark: 'rgba(255,255,255,0.1)',
      },
      // Surface filter dropdown button
      'surface-filter-button': {
        'hover-light': 'rgba(29, 29, 29, 0.05)',
        'hover-dark': 'rgba(255, 255, 255, 0.05)',
      },
      // Action menu (e.g. minimized post modal context menu)
      'action-menu-light': 'rgba(255, 255, 255, 0.86)',
      'action-menu-dark': 'rgba(32, 33, 35, 0.86)',
      // Bg for loading image
      'bgi-red': '#FFA0A0',
      'bgi-blue': '#667EFC',
      'bgi-green': '#B2F6B9',
      'bgi-yellow': '#FFD954',
      'bgi-purple': '#AD94F3',
      'bgi-pink': '#FEB8FF',
      'bgi-cyan': '#A5FFEF',
      'bgi-orange': '#FFC195',
      // Divider
      'divider-gray': 'rgba(0, 0, 0, 0.2)',
      'divider-gray-dark': 'rgba(255, 255, 255, 0.3)',
      'divider-popover': 'rgba(29, 29, 29, 0.1)',
      'divider-popover-dark': 'rgba(36, 37, 39, 0.8)',
      // Drag and drop
      'drag-overlay-dark': '#FFBF00',
      // OzLoadingSpinner light mode color
      'loading-spinner-dark': '#282828',
      // ORIZURU COLORS
      'padlet-pink': {
        // Primary CTA
        0: '#FFF2F7',
        100: '#FFBFD8',
        200: '#FFA7C9',
        300: '#FF92BE',
        400: '#FF5E9F',
        500: '#FF4081',
        DEFAULT: '#FF4081',
        600: '#F01B68',
        700: '#C80764',
        800: '#890444',
        900: '#700639',
        1000: '#45092E',
      },
      scarlet: {
        0: '#FFF2F2',
        100: '#FFDADA',
        200: '#FFC6CB',
        300: '#FFA6A6',
        400: '#FF7568',
        500: '#F44336',
        600: '#E10B0B',
        DEFAULT: '#E10B0B',
        700: '#C20000',
        800: '#980000',
        900: '#7C0000',
        1000: '#530000',
      },
      tangerine: {
        // Content Picker, Grid Format
        0: '#FFF5EF',
        100: '#FFE9C8',
        200: '#FFDAA3',
        300: '#FFC480',
        400: '#FFA665',
        500: '#FF833E',
        DEFAULT: '#FF833E',
        600: '#FF6F07',
        700: '#E56000',
        800: '#BB4F00',
        900: '#913D00',
        1000: '#662B00',
      },
      canary: {
        // Dark mode selection, loading
        0: '#FFF9E5',
        100: '#FFF4CE',
        200: '#FFEBA4',
        300: '#FFDF7A',
        400: '#FFD54F',
        500: '#FEC006',
        DEFAULT: '#FEC006',
        600: '#F3AF00',
        700: '#D69A00',
        800: '#A77900',
        900: '#7D5B01',
        1000: '#554700',
      },
      park: {
        // Content Picker, Canvas Format
        0: '#EEFFED',
        100: '#DEFFDD',
        200: '#B5FFB8',
        300: '#92FC89',
        400: '#53E047',
        500: '#3DC731',
        DEFAULT: '#3DC731',
        600: '#0A9D15',
        700: '#067309',
        800: '#004E00',
        900: '#003D00',
        1000: '#012D00',
      },
      oceanic: {
        // Content Picker, Shelf Format
        0: '#ECF6FF',
        100: '#BBEAFF',
        200: '#9ADDFB',
        300: '#83CBFF',
        400: '#5BB3FE',
        500: '#3992DE',
        DEFAULT: '#3992DE',
        600: '#0E78DB',
        700: '#015CB0',
        800: '#00418E',
        900: '#01326B',
        1000: '#071E4A',
      },
      grape: {
        // Light mode selection
        0: '#F7EDFF',
        100: '#EED8FF',
        200: '#E7BFFF',
        300: '#D9A8FF',
        400: '#C388FF',
        500: '#9466E8',
        DEFAULT: '#9466E8',
        600: '#8B00F9',
        700: '#6E00C5',
        800: '#520085',
        900: '#3D0062',
        1000: '#280040',
      },
      grey: {
        0: '#FBFBFB',
        50: '#F5F6F7',
        100: '#EBECEF',
        200: '#CED0D4',
        300: '#B2B4B9',
        400: '#94969B',
        500: '#75777C',
        DEFAULT: '#75777C',
        600: '#545559',
        700: '#3C3D40',
        800: '#242527',
        850: '#1B1B1B',
        900: '#111111',
        1000: '#000000',
      },
      'whiteboard-shape-border': {
        grape: '#9033F0',
      },
      'whiteboard-shape-fill': {
        park: '#D3F7D4',
      },
      neon: {
        // highlighter color for whiteboard
        pink: '#F55AEF',
        orange: '#FF7008',
        yellow: '#EBFF00',
        green: '#6EFF3A',
        blue: '#6BEDFF',
      },
      lavender: {
        // Hexagon in post modal
        500: '#BE66E8',
        DEFAULT: '#BE66E8',
      },
      teal: {
        0: '#f0fdfa',
        100: '#ccfbf1',
        200: '#99f6e4',
        300: '#5eead4',
        400: '#2dd4bf',
        500: '#14b8a6',
        DEFAULT: '#14b8a6',
        600: '#0d9488',
        700: '#0f766e',
        800: '#115e59',
        900: '#134e4a',
        1000: '#0d3331',
      },
      white: {
        // Dark-mode Text color, modal color
        100: '#FFFFFF', // primary
        DEFAULT: '#FFFFFF',
        200: 'rgba(255,255,255,0.7)', // secondary
        300: 'rgba(255,255,255,0.5)', // tertiary
        400: 'rgba(255,255,255,0.2)', // disabled
        disabled: 'rgba(255,255,255,0.2)',
      },
      highway: {
        // Light-mode text color
        100: '#1D1D1D', // primary
        DEFAULT: '#1D1D1D',
        200: 'rgba(0,0,0,0.54)', // secondary
        300: 'rgba(0,0,0,0.38)', // tertiary
        400: 'rgba(29, 29, 29, 0.2)', // disabled
        disabled: 'rgba(0,0,0,0.2)',
      },
      steel: {
        // Buttons
        100: '#ECECEC',
        200: '#C8C8C8',
        300: '#555555',
        400: '#323335',
        light: '#ECECEC',
        dark: '#323335',
        active: {
          light: '#C8C8C8',
          dark: '#555555',
        },
        disabled: {
          light: 'transparent',
          dark: 'transparent',
        },
      },
      nightfall: {
        // Modal colors, misc background for light text
        600: 'rgba(0,0,0,0.7)', // timestamps
        700: '#2C2C2C', // hexagons
        800: '#212224', // modal secondary color
        900: '#111111', // modal primary color
        DEFAULT: '#111111',
      },
      ghost: {
        // misc background for dark text
        50: '#F8F8F8',
        100: '#F6F6F6',
      },
      'light-text': {
        100: '#FFFFFF',
        DEFAULT: '#FFFFFF',
        200: 'rgba(255, 255, 255, 0.7)', // secondary
        300: 'rgba(255,255,255,0.5)', // tertiary
        400: 'rgba(255,255,255,0.2)', // disabled
        secondary: 'rgba(255, 255, 255, 0.7)', // secondary
        tertiary: 'rgba(255,255,255,0.5)',
        disabled: 'rgba(255,255,255,0.2)',
      },
      'dark-text': {
        100: '#111111', // primary
        DEFAULT: '#111111',
        200: 'rgba(0, 0, 0, 0.54)', // secondary
        300: 'rgba(0, 0, 0, 0.38)', // tertiary
        400: 'rgba(29, 29, 29, 0.2)', // disabled
        secondary: 'rgba(29, 29, 29, 0.54)', // secondary
        tertiary: 'rgba(29, 29, 29, 0.38)', // tertiary
        disabled: 'rgba(29, 29, 29, 0.2)', // disabled
      },
      'light-ui': {
        stroke: 'rgba(255, 255, 255, 0.3)',
        100: '#FFFFFF',
        150: '#FBFBFB',
        200: '#F5F6F7',
        300: '#ECECEC',
        400: '#E0E0E0',
        500: '#C8C8C8',
      },
      'dark-ui': {
        stroke: 'rgba(0,0,0,0.2)',
        100: '#111111',
        150: '#1A1A1A',
        200: '#242527',
        300: '#323335',
        400: '#3C3D3F',
        500: '#535557',
      },
      'common-ui': {
        l80: 'rgba(255, 255, 255, 0.8)',
        l86: 'rgba(255, 255, 255, 0.86)',
        l77: 'rgba(255, 255, 255, 0.77)',
        d70: 'rgba(0, 0, 0, 0.7)',
        d86: 'rgba(0, 0, 0, 0.86)',
        'darkened-bg': 'rgba(0, 0, 0, 0.2)',
        'lightened-bg': 'rgba(204, 204, 204, 0.2)',
      },
      'overlay-text-bg': {
        'light-mode-light-hover': 'rgba(29, 29, 29, 0.1)',
        'light-mode-dark-hover': 'rgba(255, 255, 255, 0.2)',
        'dark-mode-light-hover': 'rgba(0, 0, 0, 0.1)',
        'dark-mode-dark-hover': 'rgba(255, 255, 255, 0.2)',
        d70: 'rgba(0, 0, 0, 0.7)',
      },
      thumb: {
        light: 'rgba(17, 17, 17, 0.5)',
        dark: 'rgba(255, 255, 255, 0.5)',
      },
      track: {
        light: 'transparent',
        dark: 'transparent',
      },
      'thumb-hover': {
        light: 'rgba(17, 17, 17, 0.6)',
        dark: 'rgba(255, 255, 255, 0.6)',
      },
      danger: {
        50: 'rgba(255, 55, 55, 0.5)',
        100: '#FF2010',
      },
      // colors for error messages and success messages in dashboard settings
      error: {
        100: '#f2dede',
        600: '#a94442',
      },
      success: {
        100: '#dff0d8',
        600: '#3c763d',
      },
      // Old post color, we haven't decided the new colors what name they will have yet
      // => put the old post colors here for now, will remove and place them with new colors when they are available
      'old-post': {
        red: '#FFCDD2',
        'red-dark': '#B71C1C',
        orange: '#FFECB3',
        'orange-dark': '#FF6F00',
        green: '#C8E6C9',
        'green-dark': '#1B5E20',
        blue: '#B3E5FC',
        'blue-dark': '#0D47A1',
        purple: '#E1BEE7',
        'purple-dark': '#4A148C',
        light: '#FFFFFF',
        dark: '#212121',
      },
      'stalker-bar': {
        light: 'rgba(0, 0, 0, 0.04)',
        dark: 'rgba(0, 0, 0, 0.06)',
      },
      'twitter-blue': '#1D9BF0',
    },
    placeholderColor: {
      'light-text-200': 'rgba(255, 255, 255, 0.7)',
      'light-text-300': 'rgba(255, 255, 255, 0.5)',
      'light-text-400': 'rgba(255, 255, 255, 0.2)',
      'dark-text-200': 'rgba(0, 0, 0, 0.54)',
      'dark-text-300': 'rgba(0, 0, 0, 0.38)',
      'dark-text-400': 'rgba(0, 0, 0, 0.2)',
    },
    fontFamily: {
      // defined in tailwind.css together with some defaut font features
      // sans: ['Inter var', 'Inter', 'sans-serif']

      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],

      // used when changing padlet font
      serif: ['Lora', 'Georgia', 'Times New Roman', 'serif'],
      'padlet-monospace': ['Average Mono', 'Courier New', 'Courier', 'monospace'],
      cursive: ['Comic Neue Angular', 'Comic Sans MS', 'Comic Sans', 'sans-serif'],
    },
    extend: {
      spacing: {
        0.25: '0.0625rem', // 1px
        0.75: '0.1875rem', // 3px
        0.875: '0.21875rem', // 3.5px
        1.125: '0.28125rem', // 4.5px
        1.25: '0.3125rem', // 5px
        1.75: '0.4375rem', // 7px
        2.25: '0.5625rem', // 9px
        2.375: '0.59375rem', // 9.5px
        2.5: '0.625rem', // 10px
        2.75: '0.6875rem', // 11px
        3.25: '0.8125rem', // 13px
        3.5: '0.875rem', // 14px
        3.75: '0.9375rem', // 15px
        4.25: '1.0625rem', // 17px
        4.5: '1.125rem', // 18px
        4.75: '19px', // 19px
        5.5: '22px', // 22px
        5.75: '1.4375rem', // 23px
        6.5: '1.625rem', // 26px
        6.75: '1.6875rem', // 27px
        7: '1.75rem', // 28px
        7.5: '1.875rem', // 30px
        8: '2rem', // 32px
        8.5: '34px', // 34px
        9.25: '2.3125rem', // 37px
        9.5: '2.375rem', // 38px
        9.75: '2.4375rem', // 39px
        10: '2.5rem', // 40px
        10.5: '2.625rem', // 42px
        11: '2.75rem', // 44px
        12.75: '3.1875rem', // 51px
        13: '3.25rem', // 52px
        13.5: '3.375rem', // 54px
        14: '3.5rem', // 56px
        15: '3.75rem', // 60px
        15.5: '3.875rem', // 62px
        16: '4rem', // 64px
        17.5: '4.375rem', // 70px
        18: '4.5rem', // 72px
        19: '4.75rem', // 76px
        21: '5.25rem', // 84px
        22: '5.5rem', // 88px
        22.5: '5.625rem', // 90px
        23: '5.75rem', // 92px
        26: '6.5rem', // 104px
        30: '7.5rem', // 120px
        31: '7.75rem', // 124px
        33: '8.25rem', // 132px
        35: '8.75rem', // 140px
        36: '9rem', // 144px
        38: '9.5rem', // 148px
        41: '10.25rem', // 164px
        43: '10.75rem', // 172px
        46.5: '11.625rem', // 186px
        47: '12rem', // 192px
        47.5: '12.125rem', // 194px
        50: '12.5rem', // 200px
        51.5: '12.875rem', // 206px
        54: '13.5rem', // 216px
        56: '14rem', // 224px
        57: '14.5rem', // 232px
        60: '15rem', // 240px
        62.5: '15.625rem', // 250px
        64: '16rem', // 256px
        66: '16.5rem', // 264px
        68.5: '17.125rem', // 274px
        69: '17.25rem', // 276px
        70: '17.5rem', // 280px
        71: '17.75rem', // 284px
        77: '19.25rem', // 308px
        78: '19.5rem', // 312px
        81: '20.25rem', // 324px
        82: '20.5rem', // 328px
        82.5: '20.625rem', // 330px
        82.75: '20.6875rem', // 331px
        83: '20.75rem', // 332px
        84: '21rem', // 336px
        89: '22.25rem', // 356px
        89.5: '22.375rem', // 358px
        90: '22.5rem', // 360px
        90.5: '22.625rem', // 362px
        95: '23.75rem', // 380px
        96: '24rem', // 384px
        100: '25rem', // 400px
        101: '25.25rem', // 404px
        108: '27rem', // 432px
        109: '27.25rem', // 436px
        117: '29.25rem', // 468px
        120: '30rem', // 480px
        122: '30.5rem', // 488px
        127: '31.75rem', // 508px
        128: '32rem', // 512px
        135: '33.75rem', // 540px
        142: '35.5rem', // 568px
        144: '36rem', // 576px
        148: '37rem', // 592px
        150: '37.5rem', // 600px
        154: '38.5rem', // 616px
        156: '39rem', // 624px
        176: '44rem', // 704px
        180: '45rem', // 720px
        187: '46.75rem', // 748px
        188: '47rem', // 752px
        272: '68rem', // 1088px

        // mainly to get the space for the bottom address bar in safari in ios 15+
        // normally env(safe-area-inset-bottom) but our pages already spare a little space by using window.innerHeight and store it in var(--vh100) to use as the page height
        // => we need the + var(--vh100) - 100vh to compensate
        'safe-inset-bottom': 'calc(env(safe-area-inset-bottom) + var(--vh100) - 100vh)',
        'safe-inset-right': 'env(safe-area-inset-right)',
        'safe-inset-left': 'env(safe-area-inset-left)',
      },
      fontSize: {
        inherit: 'inherit',
        // Follow https://rsms.me/inter/dynmetrics/
        'alert-title': ['24px', { lineHeight: '1.2', letterSpacing: '-0.019em' }],
        'alert-body-small': ['14px', { lineHeight: '20px', letterSpacing: '-0.006em' }],
        'post-modal-body': ['17px', { lineHeight: '1.5', letterSpacing: '-0.013em' }],
        'input-body-small': ['14px', { lineHeight: '20px', letterSpacing: '-0.006em' }],
        'input-body': ['17px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        'settings-nav-heading': ['32px', { lineHeight: '48px', letterSpacing: '-0.021em' }],
        'surface-title': ['24px', { lineHeight: '30px', letterSpacing: '-0.0255em' }],
        '11-16': ['11px', { lineHeight: '16px', letterSpacing: '-0.0009em' }],
        '12-12': ['12px', { lineHeight: '12px' }],
        '12-14': ['12px', { lineHeight: '14px' }],
        '12-15': ['12px', { lineHeight: '15px' }],
        '12-16': ['12px', { lineHeight: '16px' }],
        '12-17': ['12px', { lineHeight: '17px' }],
        '12-18': ['12px', { lineHeight: '18px' }],
        '14-19': ['14px', { lineHeight: '19px', letterSpacing: '-0.006em' }],
        '14-16': ['14px', { lineHeight: '16px', letterSpacing: '-0.013em' }],
        '14-24': ['14px', { lineHeight: '24px' }],
        14: ['14px', { lineHeight: '21px', letterSpacing: '-0.006em' }],
        17: ['17px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        '17-20': ['17px', { lineHeight: '20px', letterSpacing: '-0.013em' }],
        '17-21': ['17px', { lineHeight: '21px', letterSpacing: '-0.013em' }],
        '17-22': ['17px', { lineHeight: '22px', letterSpacing: '-0.013em' }],
        '18-24': ['18px', { lineHeight: '24px', letterSpacing: '-0.014em' }],
        20: ['20px', { lineHeight: '28px', letterSpacing: '-0.017em' }],
        '20-24': ['20px', { lineHeight: '24px', letterSpacing: '-0.017em' }],
        // from orizuru
        'title-1': ['32px', { lineHeight: '48px', letterSpacing: '-0.00065625em' }],
        'heading-1-large': ['48px', { lineHeight: '64px', letterSpacing: '-0.022em' }],
        'heading-1': ['34px', { lineHeight: '43px', letterSpacing: '-0.021em' }],
        'heading-2-large': ['28px', { lineHeight: '37px', letterSpacing: '-0.019em' }],
        'heading-2': ['24px', { lineHeight: '32px', letterSpacing: '-0.019em' }],
        'heading-3': ['20px', { lineHeight: '26px', letterSpacing: '-0.0005em' }],
        'heading-4': ['17px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        // marketing
        'marketing-1': ['64px', { lineHeight: '72px', letterSpacing: '-2.5px' }],
        'marketing-2': ['56px', { lineHeight: '64px', letterSpacing: '-2.5px' }],
        'marketing-3': ['48px', { lineHeight: '55px', letterSpacing: '-2px' }],
        'marketing-4': ['40px', { lineHeight: '47px', letterSpacing: '-1.5px' }],
        'marketing-5': ['34px', { lineHeight: '37px', letterSpacing: '-0.91px' }],
        'whiteboard-body': ['24px', { lineHeight: '32px', letterSpacing: '-0.019em' }],
        'whiteboard-body-small': ['20px', { lineHeight: '28px', letterSpacing: '-0.017em' }],
        'whiteboard-body-extra-small': ['16px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        'whiteboard-body-tiny': ['14px', { lineHeight: '20px', letterSpacing: '-0.006em' }],
        'body-large': ['20px', { lineHeight: '28px', letterSpacing: '-0.017em' }],
        body: ['17px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        'body-posts': ['17px', { lineHeight: '26px', letterSpacing: '-0.013em' }],
        'body-small': ['14px', { lineHeight: '20px', letterSpacing: '-0.006em' }],
        'body-small-posts': ['14px', { lineHeight: '21px', letterSpacing: '-0.006em' }],
        'body-extra-small': ['12px', { lineHeight: '17px' }],
        'body-tiny': ['10px', { lineHeight: '14px', letterSpacing: '0.01em' }],
        'quote-regular': ['16px', { lineHeight: '26px', letterSpacing: '-0.011em' }],
        'quote-small': ['14px', { lineHeight: '20px', letterSpacing: '-0.006em' }],
        'login-heading-1': ['34px', { lineHeight: '40px', letterSpacing: '-0.021em' }],
        'login-body': ['17px', { lineHeight: '24px', letterSpacing: '-0.013em' }],
        'file-ext-large': ['56px', { lineHeight: '56px', letterSpacing: '-0.022em' }],
        'file-ext-normal': ['34px', { lineHeight: '43px', letterSpacing: '-0.022em' }],
        // Demo padlet panel
        'demo-padlet-heading': ['120px', { lineHeight: '120px', letterSpacing: '-0.127px' }],
        // Slideshow
        [SLIDESHOW_TEXT_CLASSNAMES.TITLE.sm]: ['40px', { lineHeight: '52px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.TITLE.md]: ['58px', { lineHeight: '75px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.TITLE.lg]: ['80px', { lineHeight: '104px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.TITLE.xl]: ['108px', { lineHeight: '140px', letterSpacing: '-0.022em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.TITLE['2xl']]: ['147px', { lineHeight: '191px', letterSpacing: '-0.022em' }],

        [SLIDESHOW_TEXT_CLASSNAMES.HEADING.sm]: ['24px', { lineHeight: '34px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.HEADING.md]: ['34px', { lineHeight: '48px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.HEADING.lg]: ['47px', { lineHeight: '65px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.HEADING.xl]: ['68px', { lineHeight: '90px', letterSpacing: '-0.022em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.HEADING['2xl']]: ['98px', { lineHeight: '122px', letterSpacing: '-0.022em' }],

        [SLIDESHOW_TEXT_CLASSNAMES.SUBHEADING.md]: ['15px', { lineHeight: '20px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.SUBHEADING.lg]: ['21px', { lineHeight: '29px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.SUBHEADING.xl]: ['31px', { lineHeight: '41px', letterSpacing: '-0.022em' }],

        [SLIDESHOW_TEXT_CLASSNAMES.BODY.xs]: ['14px', { lineHeight: '21px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.BODY.sm]: ['17px', { lineHeight: '28px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.BODY.md]: ['24px', { lineHeight: '38px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.BODY.lg]: ['34px', { lineHeight: '52px', letterSpacing: '-0.01em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.BODY.xl]: ['50px', { lineHeight: '74px', letterSpacing: '-0.022em' }],
        [SLIDESHOW_TEXT_CLASSNAMES.BODY['2xl']]: ['78px', { lineHeight: '111px', letterSpacing: '-0.022em' }],
      },
      borderWidth: {
        0.5: '0.5px',
        1: '1px',
        1.5: '1.5px',
        2: '2px',
        3: '3px',
        64: '64px',
        112: '112px',
        212: '212px',
      },
      borderRadius: {
        none: '0px',
        xs: '0.0625rem',
        sm: '0.125rem',
        DEFAULT: '0.25rem',
        md: '0.375rem',
        lg: '0.5rem',
        xl: '0.75rem',
        '2xl': '1rem',
        '2.5xl': '1.25rem', // 20px
        '3xl': '1.5rem',
        '4xl': '3rem',
        'padlet-attachment-small': '56px',
        'padlet-attachment-medium': '84px',
        'padlet-attachment-big': '112px',
        'padlet-attachment-xl': '140px',
        'padlet-attachment-3xl': '196px',
        full: '9999px',
      },
      ringWidth: {
        2.5: '2.5px',
        3: '3px',
      },
      scale: {
        '-100': '-1',
        85: '.85',
        77: '.77,',
      },
      width: {
        fit: 'fit-content',
        320: '80rem',
      },
      height: {
        fit: 'fit-content',
        vh100: 'var(--vh100)',
        62: '248px',
      },
      zIndex: {
        alert: 1100000,
        'surface-map-control': 1000001, // 1 unit greater than the Google logo
        'drop-indicator': 125000,
        'dragging-overlay': 120000,
        'global-snackbar': 110000,
        'mentions-box': 106000,
        'formatting-toolbar': 105000,
        'modal-dropdown': 101000,
        modal2: 100000,
        'upload-progress': 40000,
        dropdown: 31000,
        'onboarding-panel': 30003,
        'onboarding-demo-main': 30004,
        sidepanel: 30002,
        addpost: 30001,
        modal: 30000,
        'dockable-modal': 20000,
        'zoomed-post': 13000,
        'edit-post': 12000,
        'hover-post': 11000,
        'playing-post': 10000,
        'docked-modal': 9000,
        'minimized-modal': 8000,
        backdrop: 10,
        'search-bar': 7,
        snackbar: 5,
        globalnav: 4,
        'home-logo': 3,
        uploads: 2,
        wishlist: 1,
        underneath: -1,
        'landside-header': 30002,
        'landside-subnavigation-tab': 10000,
        'landside-header-logo': 10,
        'landside-header-tabs': 1,
        'landside-header-auth-buttons': 10,
      },
      screens: {
        // Translates to @media (hover: hover) which prevents hover states from "sticking" on devices
        // that don't support hover states, e.g mobile devices, by not applying the style
        'hover-hover': { raw: '(hover: hover)' },

        xs: '350px',

        // @see https://whimsical.com/sizes-AE8Cze2sDvD2eE56Q7eUo3
        phone: '360px',
        'tablet-portrait': '744px',
        'tablet-landscape': '1024px',
        desktop: '1440px',
        'desktop-big': '1921px',
        'desktop-2xl': '2560px',
        'desktop-3xl': '3200px',

        // Inspired by: https://tailwindcss.com/docs/responsive-design#targeting-a-breakpoint-range
        'max-tablet-landscape': { raw: 'not all and (min-width: 1024px)' },

        // Used in dashboard settings
        // + 1088px (68rem) -> dashboard settings section width
        // + 64px (4rem) -> dashboard settings section horizontal padding
        // + 288px*2 (18rem*2) -> dashboard settings sidebar width * 2
        // = 1728 (108rem)
        '3xl': '108rem',
      },
      maxWidth: {
        39: '9.75rem',
        41: '10.25rem', // 164px
        64: '16rem',
        80: '20rem', // 320px
        95: '23.75rem',
        95: '23.75rem',
        101: '25.25rem', // 404px
        135: '33.75rem', // 540px, Stream posts max width with standard post size
        187: '46.75rem', // 748px, Stream posts max width with wide post size
        240: '60rem', // Used in the new Memberships page
        272: '68rem',
        320: '80rem',
        672: '672px',
      },
      minWidth: {
        0: '0px',
        8: '2rem',
        11: '2.75rem',
        90: '22.5rem',
        101: '25.25rem', // 404px
        135: '33.75rem', // 540px
      },
      maxHeight: {
        0: '0px',
        screen: '100vh',
        '1/4': '25%',
        '1/2': '50%',
        '9/10': '90%',
        full: '100%',
        vh100: 'var(--vh100)',
        10: '2.5rem', // 40px
        64: '16rem', // 256px
        72: '18rem', // 288px
        80: '20rem', // 320px
        84: '21rem', // 336px
        96: '24rem',
        none: 'none',
        'full-with-space-8': 'calc(100% - 2rem)', // full parent height with 32px space
        'screen-with-space-8': 'calc(100vh - 2rem)', // full screen with 32px space
      },
      minHeight: {
        0: '0px',
        4: '1rem',
        4.5: '1.125rem', // 18px
        6: '1.5rem', // 24px
        8: '2rem', // 32px
        8.5: '34px',
        10: '2.5rem', // 40px
        12: '3rem', // 48px
        13: '3.25rem', // 52px
        14: '3.5rem', // 56px
        16: '4rem', // 64px
        20: '5rem', // 80px
        17.25: '4.3125rem', // 69px
        25: '6.25rem', // 100px
        screen: '100vh',
        vh100: 'var(--vh100)',
        '1/4': '25%',
        '1/2': '50%',
        full: '100%',
        96: '24rem',
        101: '25.25rem', // 404px
        135: '33.75rem', // 540px
        auto: 'auto',
      },
      animation: {
        shake: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        // to use this animation, we will also have to specify the colors with pulse-bg-from and pulse-bg-to
        // e.g. animate-pulse-bg-color pulse-bg-from-park-0 pulse-bg-to-park-200
        'pulse-bg-color-fast': 'pulse-bg-color 800ms ease-in-out infinite',
        'pulse-bg-color': 'pulse-bg-color 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite',
        'pulse-bg-color-slow': 'pulse-bg-color 2.4s ease-in-out infinite',
        'fade-in': 'fade-in 0.2s ease-out',
        'fade-in-slow': 'fade-in 0.5s ease-out',
        'fade-out-slow': 'fade-out 0.5s ease-in forwards',
        'fade-out': 'fade-out 0.2s ease-in forwards',
        'slide-up': 'slide-up 0.3s ease-out',
        'fade-in-fast': 'fade-in 0.15s ease-out',
        'slide-to-right': 'slide-to-right 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards',
        'slide-to-left': 'slide-to-left 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards',
        wiggle: 'wiggle 0.455s ease-in-out',
        'dropdown-in': 'dropdown-in 0.2s ease-out',
        blink: 'fade-in 0.8s infinite',
        'demo-padlet-circle-1-animation':
          'demo-padlet-circle-1-animation 2600ms cubic-bezier(0.36, 0.36, 0.38, 0.96) infinite',
        'demo-padlet-circle-2-animation':
          'demo-padlet-circle-2-animation 2600ms cubic-bezier(0.36, 0.36, 0.38, 0.96) infinite',
        'demo-padlet-rectangle-1-animation':
          'demo-padlet-rectangle-1-animation 1200ms cubic-bezier(0.36, 0.36, 0.38, 0.96) infinite alternate',
        'demo-padlet-rectangle-2-animation':
          'demo-padlet-rectangle-2-animation 1200ms cubic-bezier(0.36, 0.36, 0.38, 0.96) infinite alternate',
      },
      keyframes: {
        shake: {
          '10%, 90%': { transform: 'translate3d(-1px, 0, 0)' },
          '20%, 80%': { transform: 'translate3d(2px, 0, 0)' },
          '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)' },
          '40%, 60%': { transform: 'translate3d(4px, 0, 0)' },
        },
        'pulse-bg-color': {
          '0%, 100%': { 'background-color': 'var(--pulse-bg-from)' },
          '50%': { 'background-color': 'var(--pulse-bg-to)' },
        },
        'fade-in': {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
        'fade-out': {
          '0%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        'slide-up': {
          '0%': { transform: 'translateY(100%)' },
          '100%': { transform: 'translateY(0)' },
        },
        'slide-to-right': {
          '0%': { transform: 'translateX(-100%)', opacity: '0' },
          '100%': { transform: 'translateX(0%)', opacity: '1' },
        },
        'slide-to-left': {
          '0%': { transform: 'translateX(0%)', opacity: '1' },
          '100%': { transform: 'translateX(-100%)', opacity: '0' },
        },
        wiggle: {
          '26%': { transform: 'rotate(-1.2deg)' },
          '75%': { transform: 'rotate(1.2deg)' },
          '0%, 100%': { transform: 'rotate(0deg)' },
        },
        'dropdown-in': {
          '0%': { transform: 'translateY(-50%) scaleY(0)', opacity: '0' },
          '100%': { transform: 'translateY(0%) scaleY(1)', opacity: '1' },
        },
        'demo-padlet-circle-1-animation': {
          '0%, 100%': { transform: 'scale(1)', opacity: '0.5' },
          '38.46%': { transform: 'scale(2)', opacity: '0.5' },
        },
        'demo-padlet-circle-2-animation': {
          '0%, 100%': { transform: 'scale(1)', opacity: '0.3' },
          '38.46%': { transform: 'scale(2.8)', opacity: '0.3' },
        },
        'demo-padlet-rectangle-1-animation': {
          '0%': { transform: 'scale(1, 1)', opacity: '0.30' },
          '100%': {
            transform: 'scale(var(--demo-padlet-rectangle-1-scale-x), var(--demo-padlet-rectangle-1-scale-y))',
            opacity: '0.30',
          },
        },
        'demo-padlet-rectangle-2-animation': {
          '0%': { transform: 'scale(1, 1)', opacity: '0.15' },
          '100%': {
            transform: 'scale(var(--demo-padlet-rectangle-2-scale-x), var(--demo-padlet-rectangle-2-scale-y))',
            opacity: '0.15',
          },
        },
      },
      transitionProperty: {
        width: 'width',
        'font-size': 'font-size',
      },
      transitionDuration: {
        180: '180ms',
        250: '250ms',
        400: '400ms',
      },
      transitionDelay: {
        180: '180ms',
      },
      backdropBlur: {
        6: '6px',
        10: '10px',
        12: '12px',
      },
      backgroundOpacity: {
        15: '.15',
        18: '.18',
        35: '.35',
        43: '.43',
        86: '.86',
      },
      boxShadow: {
        'drop-shadow': '2px 2px 16px rgba(0, 0, 0, 0.1)',
        'downward-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
        popover: '2px 4px 12px rgba(0,0,0,0.15)',
        'elevation-1': '0px 0.5px 1px rgba(0, 0, 0, 0.2)',
        'elevation-2': '0px 4px 8px rgba(0, 0, 0, 0.16)',
        'elevation-3': '0px 8px 16px rgba(0, 0, 0, 0.16)',
        'elevation-4': '0px 16px 32px rgba(0, 0, 0, 0.32)',
        'elevation-5': '0px 32px 64px rgba(0, 0, 0, 0.4)',
        z2: '0 3px 3px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.16)',
        z3: '0 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23)',
      },
      dropShadow: {
        popover: '2px 4px 12px rgba(0,0,0,0.15)',
        'elevation-1': '0px 0.5px 1px rgba(0, 0, 0, 0.2)',
        'elevation-3': '0px 8px 16px rgba(0, 0, 0, 0.16)',
      },
      opacity: {
        38: '.38',
      },
      gradientColorStops: (theme) => ({
        ...theme('colors'),
        'loading-text-grape': 'rgba(148, 102, 232, 0.6)',
        'loading-text-canary': 'rgba(254, 192, 6, 0.65)',
      }),
      containers: {
        phone: '360px',
        'tablet-portrait': '744px',
        md: '768px',
        'tablet-landscape': '1024px',
        desktop: '1440px',
        'desktop-big': '1921px',
        'desktop-2xl': '2560px',
        'desktop-3xl': '3200px',
      },
    },
  },
  plugins: [
    plugin(({ addUtilities, addVariant, e, theme }) => {
      addVariant('direction', ({ container, separator }) => {
        const result = container.clone({ nodes: [] })

        ;[('ltr', 'rtl')].forEach((dir) => {
          result.nodes = result.nodes.concat(
            addSelectors(container, (className) => {
              const dirClassName = e(`${dir}${separator}${className}`)
              return [`[dir='${dir}'] .${dirClassName}`, `[dir='${dir}'].${dirClassName}`].join(', ')
            }),
          )
        })

        return result
      })

      // Color utilities for the animation pulse-bg-color
      const pulseColorUtilities = Object.entries(flattenColorTheme(theme('colors', {}))).reduce(function (
        memo,
        [colorName, colorCode],
      ) {
        const pulseFromKey = `.${e(`pulse-bg-from-${colorName}`)}`
        const pulseToKey = `.${e(`pulse-bg-to-${colorName}`)}`
        return {
          ...memo,
          ...{
            [pulseFromKey]: { '--pulse-bg-from': colorCode },
            [pulseToKey]: { '--pulse-bg-to': colorCode },
          },
        }
      },
      {})
      addUtilities(pulseColorUtilities, { variants: ['dark'] })
      // Font
      const fontUtilities = {
        '.font-sans': {
          'font-family': "'Inter var', Inter, sans-serif",
          'font-feature-settings': "'ss01', 'cv08', 'calt'",
        },
      }

      addUtilities(fontUtilities)
      // Set font weight to 600 to all <strong> elements.
      addUtilities({
        strong: {
          'font-weight': 600,
        },
      })

      // Remove the blue box that appears when tapping on the button on android
      // @see https://stackoverflow.com/a/45050338/9548285
      // Note: This doesn't work on Firefox for Android
      // @see https://caniuse.com/?search=-webkit-tap-highlight-color
      addUtilities({
        '.tap-highlight-transparent': {
          '-webkit-tap-highlight-color': 'transparent',
        },
      })

      // Tailwind doesn't offer a class to set the `anywhere` value to
      // the `overflow-wrap` CSS property.
      // We also need to use the deprecated `break-word` value for
      // the `word-break` property because Safari doesn't support it yet.
      // @see https://caniuse.com/mdn-css_properties_overflow-wrap_anywhere
      addUtilities({
        '.break-word-anywhere': {
          'overflow-wrap': 'anywhere',
          'word-break': 'break-word',
        },
        '.text-balance': {
          'text-wrap': 'balance',
        },
        '.text-last-start': {
          'text-align-last': 'start',
        },
      })

      // Demo padlet onboarding
      addUtilities({
        '.demo-padlet-circle-1-animation': {
          'transform-origin': 'center',
        },

        '.demo-padlet-circle-2-animation': {
          'transform-origin': 'center',
        },
      })
    }),
    // Add a variant to create a variant that spawn two selectors, one with media (hover: hover) query and one without
    // This is to support both mobile and desktop pseudo classes states
    plugin(({ addVariant, postcss, e }) => {
      function walk(rule, prefix, pseudoClass, separator) {
        const originalSelector = rule.selector.slice(1) // remove the dot
        const fullPrefix = e(`${prefix}${separator}`) // e.g. hhover\:bg-red-500
        const fullSuffix = `:${pseudoClass}` // e.g. :hover:not(:active)
        rule.selector = `.${fullPrefix}${originalSelector}${fullSuffix}` // e.g. .hhover\:bg-red-500:hover:not(:active)
      }
      // Create a variant for each pseudo class
      // The first parameter is the prefix of the variant
      // The second parameter is the pseudo class selector
      // The third parameter is a boolean that indicates if the variant should only be applied to the @media (hover: hover) query
      ;[
        ['hhover', 'hover:not(:active)', true],
        ['hfocus', 'focus', false],
        ['hfocus-visible', 'focus-visible', false], // goodcheck-disable-line
        ['hactive', 'active', false],
        ['hdisabled', 'disabled', false],
      ].forEach(([prefix, pseudoClass, onlyMediaHoverHoverRule]) => {
        addVariant(prefix, ({ container, separator }) => {
          // Initialize the result Rule node { /* ... */ }
          // This blank selector Rule is needed so that we can apply two different rules
          // with the same Tailwind selector under different @media queries
          const result = postcss.rule({ selector: '' })
          // Create rules outside the @media (hover: hover) query
          if (!onlyMediaHoverHoverRule) {
            const clonedContainer = container.clone()
            clonedContainer.walkRules((rule) => {
              walk(rule, prefix, pseudoClass, separator)
            })
            result.append(clonedContainer.nodes)
          }
          // Create rules under the @media (hover: hover) query
          const hoverMediaRule = postcss.atRule({ name: 'media', params: '(hover: hover)' })
          hoverMediaRule.append(container.clone().nodes)
          hoverMediaRule.walkRules((rule) => {
            walk(rule, prefix, pseudoClass, separator)
          })
          result.append(hoverMediaRule)
          // Replace the original container with the new result
          // We have to remove all the nodes first
          container.removeAll()
          container.append(result)
        })
      })
    }),
    // Regular scrollbar styles
    plugin(({ addUtilities }) => {
      addUtilities({
        /* For firefox */
        // See this SO post on the new Chrome update that prefers the new scrollbar properties:
        // https://stackoverflow.com/questions/77919700/scrollbars-chrome-update-121
        '@supports not selector(::-webkit-scrollbar)': {
          '.scrollbar-regular': {
            scrollbarWidth: 'auto',
            scrollbarColor: 'rgba(17, 17, 17, 0.3) transparent',
          },
          '.scrollbar-regular-dark': {
            scrollbarWidth: 'auto',
            scrollbarColor: 'rgba(255, 255, 255, 0.3) transparent',
          },
        },
        '.scrollbar-regular': {
          /* width */
          '&::-webkit-scrollbar': {
            width: '14px',
            height: '14px',
          },

          /* Track */
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },

          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(17, 17, 17, 0.3)',
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            borderRadius: '4px',
          },

          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(17, 17, 17, 0.5)',
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            borderRadius: '4px',
          },
        },
        '.scrollbar-regular-dark': {
          /* width */
          '&::-webkit-scrollbar': {
            width: '14px',
            height: '14px',
          },

          /* Track */
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },

          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.3)',
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            borderRadius: '4px',
          },

          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.5)',
            border: '2px solid transparent',
            backgroundClip: 'content-box',
            borderRadius: '4px',
          },
        },
        '.scrollbar-gutter-stable': {
          'scrollbar-gutter': 'stable',
        },
      })
    }),

    /**
     * Text-direction-aware variant for `space-x-*`.
     * Taken from https://github.com/20lives/tailwindcss-rtl/blob/master/src/spaceUtilities.js
     */
    plugin(({ matchUtilities, addUtilities, theme }) => {
      matchUtilities(
        {
          'space-s': (value) => ({
            '> :not([hidden]) ~ :not([hidden])': {
              '--tw-space-s-reverse': '0',
              marginInlineEnd: `calc(${value} * var(--tw-space-s-reverse))`,
              marginInlineStart: `calc(${value} * calc(1 - var(--tw-space-s-reverse)))`,
            },
          }),
        },
        {
          supportsNegativeValues: true,
          values: theme('space'),
        },
      )
      addUtilities({
        '.space-s-reverse > :not([hidden]) ~ :not([hidden])': {
          '--tw-space-s-reverse': '1',
        },
      })
    }),
    containerQueriesPlugin,
  ],
}
